import {
  Input,
  Form,
  InputNumber,
  Space,
  Typography,
  Button,
  Divider,
  Row,
} from "antd";
import { useEffect, useState } from "react";
import Glyph from "views/Common/Glyph/Glyph";
import Tile from "views/Common/Tile/Tile";
import { checkDelayRule } from "managers/requestHelper";
import { isEmpty, isNil, partition } from "ramda";
import { useTranslation } from "react-i18next";

const STEPS = {
  RUBRIQUE: "RUBRIQUE",
  SERVICE: "SERVICE",
  DETAILS: "DETAILS",
};
const { TextArea } = Input;
const AddServices = ({
  services,
  open,
  start,
  onSubmit,
  setOpen,
  prestations,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [step, setStep] = useState(STEPS.RUBRIQUE);
  const [infoServices, setInfoServices] = useState();
  const [rubrique, setRubrique] = useState();
  const [total, setTotal] = useState(0);
  const [noAttendees, setNoAttendees] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleSubmit = async (values) => {
    const service = Object.entries(values.values).reduce(
      (acc, [key, value]) => {
        return [...acc, { key: key, value: value }];
      },
      []
    );

    try {
      await onSubmit(
        values,
        service,
        infoServices,
        total,
        noAttendees,
        rubrique?.id
      );
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    setStep(STEPS.RUBRIQUE);
    form.resetFields();
    setTotal(0);
  }, [form, open]);

  const [canOrder, canNotOrder] = !isNil(rubrique)
    ? partition(
        (s) => checkDelayRule(start, s?.form?.orderNotice),
        rubrique?.categories
      )
    : [];

  useEffect(() => {
    form.resetFields();
  }, [infoServices?.form?.elements, form]);

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      onValuesChange={(_, values) => {
        const value = infoServices?.form?.elements
          ?.filter((e) => e.type === "NUMBER")
          ?.reduce(
            (acc, curr) =>
              acc + (curr.fee || 0) * (values.values[curr.key] || 0),
            0
          );
        const noAttendees = infoServices?.form?.elements
          ?.filter((e) => e.type === "NUMBER")
          .reduce((acc, curr) => acc + (values.values[curr.key] || 0), 0);
        setNoAttendees(noAttendees);
        setTotal(Math.round(value * 100) / 100);

        const hasNumberFieldWithValue = Object.values(values.values).some(
          (value) => value > 0
        );
        setIsDisabled(!hasNumberFieldWithValue);
      }}
      initialValues={{
        values: infoServices?.form?.elements?.reduce((acc, element) => {
          return { ...acc, [element.key]: element?.type === "NUMBER" ? 0 : "" };
        }, {}),
      }}
    >
      <div style={{ display: step === STEPS.RUBRIQUE ? "block" : "none" }}>
        <Row
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            height: "70vh",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "16px",
              }}
            >
              <Typography.Text type="secondary" strong>
                {t("selectHeading")}
              </Typography.Text>
            </div>
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {services?.map((service, index) => (
                <Tile
                  key={index}
                  onClick={() => {
                    setStep(STEPS.SERVICE);
                    setRubrique(service);
                  }}
                >
                  <Typography.Text strong>{service?.label}</Typography.Text>
                  <Glyph name="arrow_forward_ios" />
                </Tile>
              ))}
            </Space>
          </div>
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="default"
              style={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              {t("back")}
            </Button>
          </div>
        </Row>
      </div>
      <div style={{ display: step === STEPS.SERVICE ? "block" : "none" }}>
        <Row
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            height: "70vh",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "16px",
              }}
            >
              <Typography.Text type="secondary" strong>
                {rubrique?.label}
              </Typography.Text>
            </div>
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {!isEmpty(canOrder) && (
                <>
                  <Typography.Text strong>
                    {t("prestationAvailable")}
                  </Typography.Text>
                  {canOrder
                    ?.filter(
                      (item) =>
                        !(prestations || [])?.some(
                          (prestation) => item?.id === prestation?.categoryId
                        )
                    )
                    ?.map((service, index) => (
                      <Tile
                        key={index}
                        onClick={() => {
                          setStep(STEPS.DETAILS);
                          setInfoServices(service);
                        }}
                      >
                        <Typography.Text strong>
                          {service?.name}
                        </Typography.Text>
                        <Glyph name="arrow_forward_ios" />
                      </Tile>
                    ))}
                  <Divider style={{ margin: "5px" }}></Divider>
                </>
              )}

              {!isEmpty(canNotOrder) && (
                <>
                  <Typography.Text strong>
                    {t("prestationNoAvailable")}
                  </Typography.Text>
                  <Typography.Text type="secondary" strong>
                    {t("orderOutOfTime")}
                  </Typography.Text>
                  {canNotOrder?.map((service, index) => (
                    <Tile key={index} style={{ cursor: "default" }}>
                      <Typography.Paragraph style={{ margin: "0" }}>
                        {service?.name}
                      </Typography.Paragraph>
                    </Tile>
                  ))}
                </>
              )}
            </Space>
          </div>

          <div
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="default"
              style={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                setStep(STEPS.RUBRIQUE);
              }}
            >
              {t("back")}
            </Button>
          </div>
        </Row>
      </div>
      <div style={{ display: step === STEPS.DETAILS ? "block" : "none" }}>
        <Row
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            height: "70vh",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "16px",
              }}
            >
              <Typography.Text type="secondary" strong>
                {rubrique?.label} / {infoServices?.name}
              </Typography.Text>
            </div>
            <div
              style={{ overflow: "auto", height: "400px", padding: "0 8px" }}
            >
              <Space
                style={{ width: "100%", marginBottom: "16px" }}
                direction="vertical"
                size="small"
              >
                {infoServices?.form?.elements?.map((element, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography.Text strong>{element?.name}</Typography.Text>
                      {element?.type === "NUMBER" && (
                        <Typography.Text strong>
                          {element?.fee.toFixed(2)}{" "}
                          {infoServices?.form?.currency}
                        </Typography.Text>
                      )}
                    </div>

                    {element?.type === "NUMBER" ? (
                      <Tile>
                        <Typography.Text strong>{t("amount")}</Typography.Text>
                        <Form.Item
                          noStyle
                          name={["values", element?.key]}
                          rules={[
                            {
                              required: element?.required,
                              message: t("obligatory"),
                            },
                          ]}
                        >
                          <InputNumber min={0} defaultValue={0} />
                        </Form.Item>
                      </Tile>
                    ) : (
                      <Form.Item
                        name={["values", element?.key]}
                        rules={[
                          {
                            required: element?.required,
                            message: t("obligatory"),
                          },
                        ]}
                      >
                        <TextArea
                          maxLength={500}
                          style={{
                            height: 100,
                            resize: "none",
                          }}
                        />
                      </Form.Item>
                    )}
                    <Typography.Text strong type="secondary">
                      {element?.description}
                    </Typography.Text>
                  </div>
                ))}
              </Space>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                margin: "8px 0",
              }}
            >
              <Typography.Text strong style={{ textTransform: "uppercase" }}>
                {"total"}
              </Typography.Text>
              <Typography.Text strong>{total.toFixed(2)}€</Typography.Text>
            </div>
          </div>
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="default"
              style={{ marginRight: "8px", width: "100px" }}
              onClick={() => {
                setStep(STEPS.SERVICE);
              }}
            >
              {t("back")}
            </Button>
            <Button
              style={{ width: "100px" }}
              type="primary"
              disabled={isDisabled}
              onClick={() => {
                form.submit();
              }}
            >
              {t("add")}
            </Button>
          </div>
        </Row>
      </div>
    </Form>
  );
};

export default AddServices;
