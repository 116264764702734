import * as R from "ramda";
import moment from "moment";

const getEnhancedLocation = (item) =>
  new Promise((resolve) => {
    item.enhancedLocation.getAsync((res) => {
      if (res.status === window.Office.AsyncResultStatus.Failed) resolve([]);
      else resolve(res.value);
    });
  });

const getLocation = (item) =>
  new Promise((resolve) => {
    item.location.getAsync((res) => {
      if (res.status === window.Office.AsyncResultStatus.Failed) resolve([]);
      else resolve(res.value);
    });
  });

const getRecipients = (recipients) => {
  return new Promise((resolve, reject) => {
    if (recipients && typeof recipients.getAsync === "function") {
      recipients.getAsync(function (asyncResult) {
        resolve(asyncResult.value);
      });
    } else {
      reject();
    }
  });
};

const getAllRecipients = async (item) => {
  return new Promise((resolve, reject) => {
    let listPromise = [];
    listPromise.push(getRecipients(item.requiredAttendees));
    listPromise.push(getRecipients(item.optionalAttendees));

    Promise.all(listPromise)
      .then((results) => {
        let res = [];
        results.forEach((recipients) => {
          res = res.concat(recipients);
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getCustomProp = async (item, propertyName) => {
  return new Promise((resolve) => {
    item.loadCustomPropertiesAsync(function (result) {
      if (!result.value) return {};
      let value = result.value.get(propertyName);
      resolve(value);
    });
  });
};

const setCustomProp = async (item, value, propertyName) => {
  return new Promise((resolve, reject) => {
    item.loadCustomPropertiesAsync(function (result) {
      if (!result.value) reject();
      result.value.set(propertyName, value);
      result.value.saveAsync(() => resolve());
    });
  });
};

const setRoom = (item, roomEmail) => {
  return new Promise((resolve, reject) => {
    item.enhancedLocation.addAsync(
      [
        {
          id: roomEmail,
          type: "room",
        },
      ],
      () => {}
    );
  });
};

const OfficeHelper = {
  delegationProperties: (item, currentUser) => {
    return new Promise((resolve) => {
      if (!item.getSharedPropertiesAsync) return resolve();
      item.getSharedPropertiesAsync((data) => {
        let res = data.value?.owner?.targetMailbox || {};
        res.from = currentUser;
        resolve(res);
      });
    });
  },
  getItemId: (item) => {
    return new Promise((resolve) => {
      // SB-180
      resolve(item?.itemId);
    });
  },
  getLocation: async (item, rooms) => {
    const { logger } = global;
    let enhancedLocations = await getEnhancedLocation(item);

    let location = await getLocation(item);
    if (!enhancedLocations || !enhancedLocations.length)
      enhancedLocations = [{ displayName: location }];

    let locations = enhancedLocations
      .map((loc) => {
        let room = rooms.find(
          (r) =>
            (loc.emailAddress || "").toUpperCase() === r.email?.toUpperCase() ||
            (loc.displayName || "").toUpperCase() === r.label?.toUpperCase()
        );
        return room;
      })
      .filter((loc) => !R.isNil(loc));

    logger.debug("getLocation", {
      enhancedLocations,
      location,
      locations,
      rooms,
    });

    return locations[0];
  },
  getSchedule: (item) => {
    return new Promise((resolve, reject) => {
      item.start.getAsync((asyncResult) => {
        let start = asyncResult.value;
        item.end.getAsync((asyncResult) => {
          resolve({ start, end: asyncResult.value });
        });
      });
    });
  },
  setStart: (item, start) => {
    return new Promise((resolve, reject) => {
      item.start.setAsync(
        moment(start).toDate(),
        {
          asyncContext: { verb: "Set" },
        },
        (result) => {
          if (result.error) {
            reject(result.error);
          } else {
            resolve();
          }
        }
      );
    });
  },
  setEnd: (item, end) => {
    return new Promise((resolve, reject) => {
      item.end.setAsync(
        moment(end).toDate(),
        {
          asyncContext: { verb: "Set" },
        },
        (result) => {
          if (result.error) {
            reject(result.error);
          } else {
            resolve();
          }
        }
      );
    });
  },
  markAppointmentAsModified: async (item) => {
    const { logger } = global;

    // Artificialy mark appointment as modified adding characters
    if (!item?.body) return;

    item.body.getTypeAsync((result) => {
      if (result.value === window.Office.MailboxEnums.BodyType.Html) {
        item.body.getAsync(
          window.Office.MailboxEnums.BodyType.Html,
          (result) => {
            if (result.status === window.Office.AsyncResultStatus.Failed) {
              logger.error("failed to get body body", result);
            }

            let value = result.value;
            let reg = /<span id="(x_)+swiziExtraMarker\d"><\/span>/;
            let markerPosition = reg.exec(result.value);

            if (!markerPosition)
              value += '<span id="swiziExtraMarker1"></span>';
            else {
              // pas joli mais pas lel temps de faire mieux
              let idx = parseInt(
                markerPosition[0].split("swiziExtraMarker")[1].split('"')[0]
              );
              value = value.replace(
                reg,
                `<span id="swiziExtraMarker${++idx}"></span>`
              );
            }

            item.body.setAsync(
              value,
              { coercionType: window.Office.CoercionType.Html },
              (result) => {
                if (result.status === window.Office.AsyncResultStatus.Failed) {
                  logger.error("failed to set body body", result);
                }
              }
            );
          }
        );
      }
    });
  },
  getAllRecipients,
  setRoom,
  getCustomProp,
  setCustomProp,
  isRecurrentMeeting: (item) => {
    return new Promise((resolve) => {
      window.Office.context.mailbox.item.recurrence.getAsync((asyncResult) => {
        let recurrence = asyncResult.value;

        if (recurrence == null) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  },
};

export default OfficeHelper;
