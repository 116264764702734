import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  InputNumber,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import classNames from "classnames/bind";
import styles from "./SearchView.less";
import Tile from "views/Common/Tile/Tile";
import Glyph from "views/Common/Glyph/Glyph";
import useDesignTokens from "hook/useDesignTokens";
import { FixedSizeList as ListRoom } from "react-window";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const cx = classNames.bind(styles);
const GUTTER_SIZE = 8;

const SearchRoomView = ({
  location,
  isWorking,
  organizer,
  start,
  end,
  timezone,
  filter,
  floors,
  equipments,
  services,
  serviceCategories,
  availabilities,
  isRoomAvailable,
  currentSite,
  sites,
  onChangeSite,
  onChangeFilter,
  onSelect,
  onShowPlanning,
}) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  useEffect(() => {
    global.logger.debug("Filter", filter);
  }, [filter]);

  const ListSpaces = ({ style, index }) => (
    <Tooltip
      placement="bottom"
      title={
        <div style={{ display: "flex", flexFlow: "row" }}>
          <div style={{ marginRight: "8px" }}>
            <Glyph name="group" style={{ marginRight: "4px" }} />
            <Typography.Text style={{ color: "white" }}>
              {availabilities[index]?.seats}
            </Typography.Text>
          </div>
          <div>
            <Glyph name="filter_none" style={{ marginRight: "4px" }} />
            <Typography.Text style={{ color: "white" }}>
              {availabilities[index]?.floor}
            </Typography.Text>
          </div>
        </div>
      }
    >
      <div
        style={{
          ...style,
          height: style.height - GUTTER_SIZE,
          padding: "0 12px",
          backgroundColor: colors.light_background,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => onSelect(availabilities[index]?.id)}
      >
        <Typography.Text strong>{availabilities[index]?.label}</Typography.Text>
        <Glyph name="navigate_next" />
      </div>
    </Tooltip>
  );

  return (
    <Spin spinning={isWorking}>
      <Tile style={{ padding: "8px", height: "auto", cursor: "default" }}>
        <Glyph name="history" style={{ marginRight: "16px" }} />
        <div style={{ display: "flex", flexFlow: "column" }}>
          <Typography.Text strong>{t("dateHours")}</Typography.Text>
          <Typography.Text type="secondary">
            {t("indicateHours")}
          </Typography.Text>
        </div>
      </Tile>
      <Divider style={{ margin: "10px 0" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title strong level={4}>
          {t("findSpace")}
        </Typography.Title>
      </div>
      <Space style={{ width: "100%" }} direction="vertical" size="small">
        {sites.length > 1 && (
          <Tile>
            <Typography.Text strong>
              <Glyph
                name="domain"
                style={{
                  color: colors.secondary_base,
                  marginRight: "8px",
                  fontWeight: "normal",
                }}
              />
            </Typography.Text>
            <Select
              bordered={false}
              size={"medium"}
              className="accent"
              style={{
                width: "100%",
                marginTop: -8,
                marginBottom: -8,
                backgroundColor: colors.secondary_base.replace(
                  /(.{2})$/i,
                  "33"
                ),
              }}
              value={currentSite.id}
              onChange={(s) => {
                onChangeSite(s);
              }}
            >
              {sites.map((s) => (
                <Option value={s.id} key={s.id}>
                  {s.label}
                </Option>
              ))}
            </Select>
          </Tile>
        )}

        <Tile>
          <Typography.Text strong>
            <Glyph
              name="group"
              style={{
                color: colors.secondary_base,
                marginRight: "8px",
                fontWeight: "normal",
              }}
            />
            {t("minPlaces")}
          </Typography.Text>
          <InputNumber
            size={"medium"}
            min={1}
            value={filter?.attendeesCount}
            onChange={(v) => onChangeFilter({ attendeesCount: v })}
          />
        </Tile>

        <Tile>
          <Typography.Text strong>
            <Glyph
              name="filter_none"
              style={{
                color: colors.secondary_base,
                marginRight: "8px",
                fontWeight: "normal",
              }}
            />
            {t("floor")}
          </Typography.Text>
          <Select
            size={"medium"}
            style={{ width: 180 }}
            value={filter.floor}
            onChange={(s) => {
              onChangeFilter({ floor: s });
            }}
          >
            {floors.map((f) => (
              <Option value={f.order} key={f.order}>
                {f.label}
              </Option>
            ))}
          </Select>
        </Tile>

        <Tile>
          <Typography.Text strong>
            <Glyph
              name="devices"
              style={{
                color: colors.secondary_base,
                marginRight: "8px",
                fontWeight: "normal",
              }}
            />
            {t("equipments")}
          </Typography.Text>
          <Select
            mode="multiple"
            size={"medium"}
            style={{ width: 180 }}
            value={filter.equipments}
            onChange={(s) => onChangeFilter({ equipments: s })}
            placeholder={t("none")}
            maxTagCount="responsive"
            notFoundContent={
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Glyph name="devices" />
                <Typography.Text>{t("noEquipments")}</Typography.Text>{" "}
              </div>
            }
          >
            {equipments.map((eq) => (
              <Option value={eq.id} key={eq.id}>
                {eq.label}
              </Option>
            ))}
          </Select>
        </Tile>

        <Tile>
          <Typography.Text strong>
            <Glyph
              name="room_service"
              style={{
                color: colors.secondary_base,
                marginRight: "8px",
                fontWeight: "normal",
              }}
            />
            {t("prestations")}
          </Typography.Text>
          <Select
            mode="multiple"
            size={"medium"}
            style={{ width: 180 }}
            value={filter.serviceCategories || []}
            onChange={(s) => onChangeFilter({ serviceCategories: s })}
            placeholder={t("noneF")}
            maxTagCount="responsive"
            notFoundContent={
              <div
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Glyph name="devices" />
                <Typography.Text>{t("noPresta")}</Typography.Text>{" "}
              </div>
            }
          >
            {serviceCategories.map((svc) => (
              <Option value={svc} key={svc}>
                {svc}
              </Option>
            ))}
          </Select>
        </Tile>
      </Space>

      <div
        style={{ textAlign: "center", marginTop: "8px", marginBottom: "8px" }}
      >
        <Typography.Text strong style={{ color: colors.primary_base }}>
          {availabilities.length} {t("spaceAvailable")}
        </Typography.Text>
      </div>

      <ListRoom height={300} itemCount={availabilities.length} itemSize={50}>
        {ListSpaces}
      </ListRoom>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "16px",
          position: "sticky",
          bottom: "0",
          background: "white",
          padding: "10px 0",
        }}
      >
        <Button type="primary" onClick={onShowPlanning}>
          {t("seeSpaces")}
        </Button>
      </div>
    </Spin>
  );
};
export default SearchRoomView;
