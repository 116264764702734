/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import * as R from "ramda";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import requestsHelper from "./requestHelper";
export const ALL_FLOORS_ORDER = -999;

const endpoint =
  process.env.REACT_APP_PLANNER_BACK_URL || "https://api-v3.planner.swizi.io";

const useSwizi = (token, locale = "fr") => {
  const { logger } = global;
  const { t } = useTranslation();

  const [swiziIsWorking, setSwiziIsWorking] = useState(false);
  const [swiziIsLoading, setSwiziIsLoading] = useState(false);
  const [swiziIsReady, setSwiziIsReady] = useState(false);
  const [swiziIsError, setSwiziIsError] = useState(false);
  const [swiziErrorMessage, setSwiziErrorMessage] = useState();

  const [rooms, setRooms] = useState([]);
  const [allSitesRooms, setAllSitesRooms] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [floors, setFloors] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [isAgent, setIsAgent] = useState(false);
  const [domains, setDomains] = useState([]);
  const [swiziToken, setSwiziToken] = useState(token);
  const [filter, setFilter] = useState({ floor: ALL_FLOORS_ORDER });
  const [availabilities, setAvailabilities] = useState([]);
  const [unfilteredAvailabilities, setUnfilteredAvailabilities] = useState([]);
  const [timezone, setTimezone] = useState("Europe/Paris");
  const [sites, setSites] = useState();
  const [currentSite, setCurrentSite] = useState();
  const [appId, setAppId] = useState();
  const [showVisitors, setShowVisitors] = useState(true);

  const sitePrev = useRef();
  const startPrev = useRef(moment());
  const endPrev = useRef(moment());
  const requestsList = useRef([]);
  const currentSiteRef = useRef();

  const initialize = useCallback(async (officeToken, appId) => {
    setSwiziIsLoading(true);
    setSwiziIsWorking(true);
    try {
      let result = await axios.post(`${endpoint}/api/v2/addon/auth`, {
        officeToken,
        plannerId: appId,
      });

      token = result?.data?.data?.token;

      setSwiziToken(token);
      setSwiziIsLoading(false);
    } catch (error) {
      logger.error(
        `Failed to authentify from Outlook (${error.message})`,
        error
      );
      setSwiziErrorMessage(
        "Impossible d'authentifier l'utilisateur Outlook : " + error.message
      );
      setSwiziIsError(true);
      setSwiziIsReady(false);
      return;
    } finally {
      setSwiziIsWorking(false);
    }

    await getConfiguration(appId, token);
  });

  const getConfiguration = useCallback(async (appId, token, attendeesCount) => {
    try {
      setSwiziIsWorking(true);
      let result;
      try {
        result = await axios.get(
          `${endpoint}/api/v2/addon/configuration?plannerId=${appId}&locale=${locale}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        logger.error(
          `Failed to get Planner configuration (${error.message})`,
          error
        );
        setSwiziErrorMessage("Impossible de récupérer la configuration.");
        setSwiziIsError(true);
        setSwiziIsReady(false);
        return;
      }

      setAppId(appId);
      let { rooms, ticketTypes, isAgent, domains, sites } = result.data.data;

      requestsList.current = requestsHelper(ticketTypes, locale);

      setSwiziToken(token);
      let lServiceCategories = [];

      rooms.forEach((room) => {
        lServiceCategories = lServiceCategories.concat(room.serviceCategories);
        lServiceCategories = lServiceCategories.filter((item, index) => {
          return lServiceCategories.indexOf(item) === index;
        });
      });

      setServiceCategories(lServiceCategories);

      setTicketTypes(ticketTypes);
      setIsAgent(isAgent);
      setDomains(domains);

      sites.forEach((site) =>
        site.floors.unshift({
          order: ALL_FLOORS_ORDER,
          label: t("all"),
        })
      );

      setSites(sites);

      let lCurrentSite = sites[0];
      const prefferedSite = localStorage.getItem(`addon-${appId}-preferedSite`);

      if (sites.length > 1 && prefferedSite) {
        if (sites.find((site) => site.id === prefferedSite))
          lCurrentSite = sites.find((site) => site.id === prefferedSite);
      }
      setShowVisitors(lCurrentSite.visitorsAllowed);
      let lAllSitesRooms = [];
      sites.forEach(
        (site) => (lAllSitesRooms = lAllSitesRooms.concat(site.rooms))
      );
      setAllSitesRooms(lAllSitesRooms);
      setCurrentSite(lCurrentSite);
      sitePrev.current = lCurrentSite.id;
      setRooms(lCurrentSite.rooms);

      setFloors(lCurrentSite.floors);
      setEquipments(lCurrentSite.equipments);

      const services = requestsList.current.getServices();
      setServices(services);
      setFilter({ floor: ALL_FLOORS_ORDER, attendeesCount });

      setSwiziIsReady(true);
    } catch (error) {
      logger.error(
        `Failed to get configuration from Swizi (${error.message})`,
        error
      );
      setSwiziErrorMessage(
        "Erreur dans l'application de la configuration : " + error.message
      );
      setSwiziIsError(true);
      setSwiziIsReady(false);
    } finally {
      setSwiziIsWorking(false);
    }
  });

  const updateSite = useCallback(async (siteId) => {
    try {
      const lCurrentSite = sites.find((site) => site.id === siteId);
      if (!lCurrentSite) return;

      setCurrentSite(lCurrentSite);
      setRooms(lCurrentSite.rooms);
      setFloors(lCurrentSite.floors);
      setEquipments(lCurrentSite.equipments);
      setShowVisitors(lCurrentSite.visitorsAllowed);
      setUnfilteredAvailabilities();
      sitePrev.current = lCurrentSite.id;
      localStorage.setItem(`addon-${appId}-preferedSite`, siteId);
    } catch (error) {
      logger.error("Error during changeSite", error);
    }
  });

  useEffect(() => {
    currentSiteRef.current = currentSite;
  }, [currentSite]);

  useEffect(() => {
    const { start, end, attendeesCount } = filter;
    applyFilter({ start, end, attendeesCount });
  }, [currentSite, rooms, floors, equipments, showVisitors]);

  const applyFilter = async (criteria) => {
    if (!currentSiteRef.current) {
      logger.debug("no site selected, ignore filter");
      return;
    }
    try {
      setSwiziIsWorking(true);
      logger.debug("new filter to apply", criteria);

      let newFilter = Object.assign(filter, criteria);

      let unfiltered = unfilteredAvailabilities;
      if (
        newFilter.start &&
        newFilter.end &&
        (sitePrev.current !== currentSiteRef.current.siteId ||
          !newFilter.start.isSame(startPrev.current) ||
          !newFilter.end.isSame(endPrev.current) ||
          unfilteredAvailabilities.length === 0)
      ) {
        logger.debug(
          `slot has change, get availability from swizi for site ${currentSite.id}`,
          currentSite
        );
        let result = await axios.post(
          `${endpoint}/api/v2/addon/availabilities`,
          {
            day: filter.start.toISOString(),
            slotStartTime: filter.start.toISOString(),
            slotEndTime: filter.end.toISOString(),
            locale,
            siteId: currentSiteRef.current.id,
          },
          {
            headers: {
              Authorization: `Bearer ${swiziToken}`,
            },
          }
        );

        unfiltered = result.data.data
          .map((un) =>
            Object.assign(un, {
              label: rooms.find((room) => room.email === un.id)?.label,
              seats: rooms.find((room) => room.email === un.id)?.seats,
              floor: rooms.find((room) => room.email === un.id)?.floor?.label,
              floorPosition: rooms.find((room) => room.email === un.id)?.floor
                ?.order,
            })
          )
          .sort((r1, r2) => (r1.label < r2.label ? -1 : 1));
        setUnfilteredAvailabilities(unfiltered);
        startPrev.current = filter.start;
        endPrev.current = filter.end;
      }
      logger.debug("unfiltered", unfiltered);
      const filtered = unfiltered.filter((rf) => {
        let room = rooms.find((r) => r.email === rf.id);

        // Filter equipments

        let roomEquipments = (room.equipments || []).map((e) => e.id);
        let commonsEquipments = R.intersection(
          newFilter.equipments || [],
          roomEquipments || []
        );
        if (
          newFilter.equipments &&
          newFilter.equipments.length > 0 &&
          commonsEquipments.length !== newFilter.equipments.length
        )
          return false;

        // Filter services
        let commonsServices = R.intersection(
          newFilter.serviceCategories || [],
          room.serviceCategories || []
        );
        if (
          newFilter.serviceCategories &&
          newFilter.serviceCategories.length > 0 &&
          commonsServices.length !== newFilter.serviceCategories.length
        )
          return false;

        if (
          newFilter.floor !== ALL_FLOORS_ORDER &&
          newFilter.floor !== room.floor?.order
        )
          return false;

        if (newFilter.attendeesCount > room.seats) return false;

        return true;
      });

      setFilter(newFilter);
      setAvailabilities(filtered);
    } catch (error) {
      logger.error("Error during filter", error);
    } finally {
      setSwiziIsWorking(false);
    }
  };

  const updateFilter = (criteria) => {
    let newFilter = Object.assign(filter, criteria);
    applyFilter(newFilter);
  };

  const isRoomAvailable = (email) => {
    return availabilities.findIndex((f) => f.id === email) !== -1;
  };

  const getSwiziUsersFromEmail = async (emails) => {
    let users = [];
    if (!swiziToken) return [];
    try {
      setSwiziIsWorking(true);
      let result = await axios.post(
        `${endpoint}/api/v2/addon/listAttendeesInfo`,
        emails,
        {
          headers: {
            Authorization: `Bearer ${swiziToken}`,
          },
        }
      );

      users = result.data.data;
    } catch (error) {
      logger.error(`Failed to get users from Swizi (${error.message})`, error);
      setSwiziIsError(true);
      setSwiziIsReady(false);
    } finally {
      setSwiziIsWorking(false);
    }

    return users;
  };

  const createSwiziUser = async ({
    email,
    firstname,
    lastname,
    company = "",
    phone,
  }) => {
    try {
      setSwiziIsWorking(true);
      let result = await axios.post(
        `${endpoint}/api/v2/addon/guest`,
        {
          email,
          firstname,
          lastname,
          company,
          phone,
        },
        {
          headers: {
            Authorization: `Bearer ${swiziToken}`,
          },
        }
      );
      return result.data.data;
    } catch (error) {
      logger.error(`Failed to create users on Swizi (${error.message})`, error);
      setSwiziIsReady(false);
    } finally {
      setSwiziIsWorking(false);
    }
  };

  const updateAttendeesCountChange = async (count) => {
    let newFilter = Object.assign(filter, { attendeesCount: count });
    applyFilter(newFilter);
  };

  const updateSchedule = async (start, end) => {
    let newFilter = Object.assign(filter, { start, end });
    applyFilter(newFilter);
  };

  const swiziManager = {
    initialize,
    getConfiguration,
    updateSite,
    updateFilter,
    updateAttendeesCountChange,
    isRoomAvailable,
    getSwiziUsersFromEmail,
    createSwiziUser,
    updateSchedule,
  };

  return {
    swiziIsLoading,
    swiziIsWorking,
    swiziIsReady,
    swiziIsError,
    swiziErrorMessage,
    rooms,
    floors,
    equipments,
    services,
    serviceCategories,
    ticketTypes,
    domains,
    isAgent,
    availabilities,
    filter,
    swiziToken,
    swiziManager,
    timezone,
    currentSite,
    sites,
    allSitesRooms,
    showVisitors,
  };
};

export default useSwizi;
